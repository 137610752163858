<template>
  <v-card class="pa-2">
    <v-tabs v-model="tab" right class="b-tab-horizontal">
      <template v-for="(header, index) in headers">
        <v-tab
          v-if="header.visible"
          :disabled="header.disabled"
          :href="`#${header.href}`"
          :key="index"
        >
          <v-icon left :size="20">
            {{ header.icon }}
          </v-icon>
          {{ header.title }}
        </v-tab>
      </template>

      <v-tabs-items v-model="tab" class="pt-2">
        <template v-for="(content, index) in headers">
          <v-tab-item v-if="content.visible" :value="content.href" :key="index">
            <component
              v-if="content.href === tab"
              :is="content.component"
              :employeeId="employeeId"
              @back="back"
            />
          </v-tab-item>
        </template>
      </v-tabs-items>
    </v-tabs>
  </v-card>
</template>

<script>
export default {
  components: {
    TabDental: () => import('@/components/pages/movements/plans/tab-dental'),
    TabHealth: () => import('@/components/pages/movements/plans/tab-health'),
    TabLife: () => import('@/components/pages/movements/plans/tab-life')
  },

  props: {
    customerId: {
      type: String,
      require: true
    },
    employeeId: {
      type: String,
      require: true
    }
  },

  data: () => ({
    tab: null,
    headers: [
      {
        href: 'tab-1',
        title: 'Plano de saúde',
        icon: 'fa-solid fa-stethoscope',
        component: 'TabHealth',
        visible: true,
        disabled: false
      },
      {
        href: 'tab-2',
        title: 'Plano odontológico',
        icon: 'fa-solid fa-tooth',
        component: 'TabDental',
        visible: true,
        disabled: false
      },
      {
        href: 'tab-3',
        title: 'Seguro de vida',
        icon: 'fa-solid fa-heart-pulse',
        component: 'TabLife',
        visible: true,
        disabled: false
      }
    ]
  }),

  methods: {
    back() {
      this.$emit('back');
    }
  }
};
</script>
